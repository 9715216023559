import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import useApi from '../base/useApi';
import useUpdateMentorSettings from './useUpdateMentorSettings';
import { useTriggers } from '../../navigation';

const useMentors = (featured = false, start = true) => {
  const auth = useSelector((state) => state.auth.data);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    page_size: 10,
    visibility:
      featured || !auth?.tenant?.is_admin
        ? 'viewable_by_tenant_students'
        : 'viewable_by_tenant_admins',
  });

  const mentors = useSelector((state) => state.mentors);
  let endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/`;
  const api = useApi();
  const { handleUpdateSettings } = useUpdateMentorSettings();
  const { handleOpenEditMentorModal } = useTriggers();

  const loadMentors = async () => {
    const params = { ...filters };
    setLoading(true);
    const response = await api.get(endpoint, params);
    if (response?.data) {
      const data = response?.data;
      if (featured) {
        dispatch(
          mentorActions.featuredMentorsUpdated({
            ...data,
            page: filters?.page ?? 1,
          })
        );
      } else {
        dispatch(
          mentorActions.mentorsUpdated({
            ...data,
            page: filters?.page ?? 1,
          })
        );
      }
    } else {
      setError(response?.error);
    }
    setHasLoadedOnce(true);
    setLoading(false);
  };

  const handleNextPage = (currentPage) => {
    setFilters((prevState) => {
      return { ...prevState, page: currentPage + 1 };
    });
  };

  const handlePreviousPage = (currentPage) => {
    setFilters((prevState) => {
      return { ...prevState, page: currentPage - 1 };
    });
  };

  const handleMentorItemSelect = (mentor) => {
    dispatch(mentorActions.editMentorUpdated(mentor));
    handleOpenEditMentorModal();
  };

  const updateLocalMentor = (mentor) => {
    const newMentors = mentors?.mentors?.results?.map((item) => {
      if (item.name === mentor.name) {
        return mentor;
      } else {
        return item;
      }
    });
    dispatch(
      mentorActions.mentorsUpdated({ ...mentors?.mentors, results: newMentors })
    );
  };

  const addMentorToFeatured = (mentor) => {
    const newFeaturedMentors = {
      ...(mentors?.featured ?? {}),
      results: [...(mentors?.featured?.results ?? []), mentor],
    };
    dispatch(mentorActions.featuredMentorsUpdated(newFeaturedMentors));
  };

  const removeMentorFromFeatured = (mentor) => {
    console.log('removing a featured mentor', mentors.featured.results, mentor);
    const newFeaturedMentors = mentors?.featured?.results?.filter(
      (item) => item.unique_id !== mentor.unique_id
    );

    dispatch(
      mentorActions.featuredMentorsUpdated({
        ...(mentors?.featured ?? {}),
        results: newFeaturedMentors,
      })
    );
  };

  const handleFeaturedStateChanged = async (mentor, status) => {
    const visibility = status
      ? 'viewable_by_tenant_students'
      : 'viewable_by_tenant_admins';

    const payload = {
      mentor_visibility: visibility,
    };
    await handleUpdateSettings(mentor, payload);
    const featuredMentor = {
      ...mentor,
      settings: { ...mentor.settings, mentor_visibility: visibility },
    };
    updateLocalMentor(featuredMentor);
    if (status === true) {
      addMentorToFeatured(mentor);
    } else if (status === false) {
      removeMentorFromFeatured(mentor);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  let mentorsToDisplay = mentors?.mentors?.results?.filter((item) =>
    item.name?.toLowerCase().includes(search?.toLowerCase())
  );
  if (!search) {
    mentorsToDisplay = mentors?.mentors?.results;
  }

  useEffect(() => {
    if (filters?.page) {
      loadMentors();
    }
  }, [filters]);

  useEffect(() => {
    if (
      start &&
      !loading &&
      ((!mentors?.mentors && !featured) || (!mentors?.featured && featured))
    ) {
      loadMentors();
    }
  }, [featured, filters, start]);

  return {
    loading,
    hasLoadedOnce,
    error,
    page: filters?.page || 1,
    handleNextPage,
    handlePreviousPage,
    handleSearchChange,
    handleFeaturedStateChanged,
    mentorsToDisplay,
    handleMentorItemSelect,
  };
};
export default useMentors;
