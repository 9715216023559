import React from 'react';
import './Recentchatbox.css';
import useSessionMessageItem from '../../hooks/api/messages/useSessionMessageItem';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

const Recentchatbox = (props) => {
  const session = props.item;
  const {
    message,
    getSessionTitle,
    handleSessionSelected,
    handleChatBoxMenuClick,
    handlePinMessage,
    setShowMenuTrigger,
    showMenuTrigger,
    showMenu,
    messageMenuRef,
    handleDeleteSession,
    handleMessageExport,
    deleting
  } = useSessionMessageItem(session, 'recent');

  return (
    <div
      className="w-layout-hflex history-element greyish-bg-in-darkmode"
      data-w-id="e28ff0df-462a-0881-4d68-7dcdc9535156"
      map=""
      onMouseEnter={() => setShowMenuTrigger(true)}
      onMouseLeave={() => setShowMenuTrigger(false)}
      map-value="chatBox"
    >
      <div className="w-layout-hflex history-icon-container light-black-bg-dark-mode">
        <img
          alt=""
          className="image-33"
          loading="lazy"
          src="/images/message-circle.svg"
        />
      </div>
      <div
        onClick={handleSessionSelected}
        title={message?.message?.data?.content}
        className="history-element-label text-inline"
      >
        {getSessionTitle(8)}
      </div>
      <div
        className="history-dropdown w-dropdown"
        data-delay={0}
        data-hover="false"
      >
        {showMenuTrigger && (
          <div
            className="history-dropdown-toggle w-dropdown-toggle"
            onClick={handleChatBoxMenuClick}
            style={{ display: 'flex' }}
            prop-events-value-onclick="handleChatBoxMenuClick"
          >
            <img
              alt=""
              className="image-37"
              loading="lazy"
              src="/images/more-vertical.svg"
            />
          </div>
        )}

        {showMenu && (
          <nav
            ref={messageMenuRef}
            className="dropdown-list-3 w-dropdown-list w--open"
          >
            <div className="w-layout-vflex user-menu-dropdown-block black-bg-dark-mode all-whiten-in-dark-mode whiten-border-in-dark-mode history-element-dropdown-block">
              <a
                className="user-menu-link manage-account-menu greyish-bg-in-darkmode history-element-dropdown-menu-link history-menu-pin-btn w-inline-block"
                data-w-id="659a69e0-aace-ea50-448d-a55c7b79d857"
                onClick={handlePinMessage}
                href="#"
              >
                <img
                  alt=""
                  className="user-menu-icon small-user-menu-icon"
                  loading="lazy"
                  src="/images/pin.svg"
                />
                <div className="user-menu-label">Pin</div>
              </a>
              <a
                className="user-menu-link billing-overview-menu greyish-bg-in-darkmode history-element-dropdown-menu-link history-menu-rename-btn w-inline-block"
                data-w-id="659a69e0-aace-ea50-448d-a55c7b79d85b"
                href="#"
                style={{ display: 'none' }}
              >
                <img
                  alt=""
                  className="user-menu-icon small-user-menu-icon"
                  loading="lazy"
                  src="/images/pencil.svg"
                />
                <div className="user-menu-label">Rename</div>
              </a>
              <a
                className="user-menu-link billing-overview-menu greyish-bg-in-darkmode history-element-dropdown-menu-link w-inline-block"
                data-w-id="659a69e0-aace-ea50-448d-a55c7b79d85f"
                href="#"
                onClick={handleMessageExport}
              >
                <img
                  alt=""
                  className="user-menu-icon small-user-menu-icon"
                  loading="lazy"
                  src="/images/download.svg"
                />
                <div className="user-menu-label">Export</div>
              </a>
              <a
                className="user-menu-link greyish-bg-in-darkmode history-element-dropdown-menu-link last-history-element-dropdown-menu-link history-menu-delete-btn w-inline-block"
                data-w-id="659a69e0-aace-ea50-448d-a55c7b79d863"
                onClick={deleting ? ()=>{} :handleDeleteSession}
                href="#"
              >
                {
                  deleting ? <div><IBLSpinLoader size={15} /></div> : (
                    <img
                      alt=""
                      className="user-menu-icon small-user-menu-icon"
                      loading="lazy"
                      src="/images/trash-2.svg"
                    />
                  )
                }
                <div className="user-menu-label">Delete</div>
              </a>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
};

export default Recentchatbox;
