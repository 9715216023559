import { useSelector } from 'react-redux';
import { useState } from 'react';
import { toast } from 'react-toastify';
import useApi from '../api/base/useApi';
import useMentorDataset from '../api/mentors/useMentorDataset';
import { useTriggers } from '../navigation';
import {
  DOCUMENTS_EXTENSIONS,
  getDatasetFormatExtensions,
  isValidUrl,
  UPLOAD_MAX_SIZE,
} from '../../utils/shared';

export default function useDatasetResourceAdd() {
  const { dataset, edit } = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const path = `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/documents/train/`;

  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [submitting, setSubmitting] = useState('');
  const api = useApi();
  const { _handleUpdateModalStatus } = useTriggers();

  const showSuccessMessage = () => {
    toast.success('Dataset resource has been submitted');
  };

  const handleSubmitFile = async () => {
    const form = document.getElementById('dataset-document-form');
    const formData = new FormData(form);
    formData.append('pathway', edit?.mentor?.name);
    formData.append('type', 'file');

    setSubmitting(true);
    const response = await api.post(path, formData, true);
    if (response?.data) {
      showSuccessMessage();
    } else {
      toast.error(response?.error);
    }
    setSubmitting(false);
  };

  const handleSubmitUrl = async () => {
    const data = {
      type: dataset?.type,
      pathway: edit?.mentor?.name,
      url,
    };
    setSubmitting(true);
    const response = await api.post(path, data);
    if (response?.data) {
      setUrl('');
      showSuccessMessage();
    } else {
      toast.error(response?.error);
    }
    setSubmitting(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (['URL', 'YouTube'].includes(dataset?.type)) {
      await handleSubmitUrl();
    } else {
      await handleSubmitFile();
    }
    _handleUpdateModalStatus('dataset-url-resource-modal', false);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      if (
        !getDatasetFormatExtensions?.[dataset?.type]?.includes(
          '.' + files[0].name.split('.').pop()
        )
      ) {
        toast.warning("The uploaded file extension isn't accepted!");
        document.getElementById('dataset-file-input').value = '';
        return;
      }
      if (files[0].size > UPLOAD_MAX_SIZE) {
        toast.warning(
          `The uploaded file shouldn't exceed ${UPLOAD_MAX_SIZE / (1024 * 1024)} MB.`
        );
        document.getElementById('dataset-file-input').value = '';
        return;
      }
      setFile(files[0]);
    }
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  return {
    dataset,
    handleFileChange,
    handleUrlChange,
    handleSubmit,
    submitting,
    url,
  };
}
