import React, { useEffect, useState } from 'react';
import './FaqModalBox.css';
import { useTriggers } from '../../hooks/navigation';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useSelector } from 'react-redux';

const FaqModalBox = () => {
  const { handleFaqMenuCloseClick } = useTriggers();
  const [loading, setLoading] = useState(false);
  const [faqContent, setFaqContent] = useState('');
  const darkMode = useSelector((state) => state.navigation.darkMode);

  const handleFetchFaqContent = () => {
    const url = `${new URL(window.location.href).origin}/FAQ.md`;
    setLoading(true);
    fetch(url)
      .then((resp) => {
        resp.text().then((content) => {
          setFaqContent(content);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
      })
      .catch(() => {
        //
        setLoading(false);
      });
  };

  useEffect(() => {
    handleFetchFaqContent();
  }, []);

  return (
    <div className="modal faq-modal">
      <div className="w-layout-vflex modal-container help-modal-container help-container">
        <div className="w-layout-hflex modal-header help-modal-header">
          <h3 className="modal-header-title">FAQ</h3>
          <div
            prop-events-value-onclick="handleModalCloseClick"
            prop-events-names="onClick"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            className="modal-close-wrapper"
            onClick={handleFaqMenuCloseClick}
          >
            <img
              src="/images/close_1close.png"
              loading="lazy"
              alt="Close Button Image"
            />
          </div>
        </div>
        <div className="w-layout-vflex modal-body help-modal-body">
          {loading && (
            <IBLSpinLoader
              size={30}
              color={!darkMode ? 'var(--primary)' : '#FFF'}
              containerHeight={'200px'}
            />
          )}
          {!loading && !!faqContent && (
            <div className="faq-embed-block all-whiten-in-dark-mode w-embed">
              <MarkdownPreview
                source={faqContent}
                style={{ padding: 16 }}
                wrapperElement={{
                  'data-color-mode': darkMode ? 'dark' : 'light',
                }}
                disableCopy={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqModalBox;
