import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { useTriggers } from '../navigation';

export default function useDatasetTypes() {
  const dispatch = useDispatch();
  const dataset = useSelector((state) => state.mentors.dataset);
  const { _handleUpdateModalStatus } = useTriggers();
  const datasetTypes = [
    {
      type: 'PDF',
      img_url: '/images/65048a233ee9c46785a13c74_inbox-copy.png',
      active: true,
    },
    {
      type: 'URL',
      img_url: '/images/65048a233ee9c46785a13c7a_link-copy.png',
      active: true,
    },
    {
      type: 'YouTube',
      img_url: '/images/65048a233ee9c46785a13c87_youtube-copy.png',
      active: true,
    },
    {
      type: 'DOCX',
      img_url: '/images/65048a233ee9c46785a13c89_docs-copy.png',
      active: true,
    },
    {
      type: 'TEXT',
      img_url:
        '/images/65048a233ee9c46785a13cc2_align-left-copy_165048a233ee9c46785a13cc2_align-left-copy.png',
      active: true,
    },
    {
      type: 'Audio',
      img_url:
        '/images/65048a233ee9c46785a13ca3_audio-speaker-copy_165048a233ee9c46785a13ca3_audio-speaker-copy.png',
      active: false,
    },
    {
      type: 'Video',
      img_url: '/images/65048a233ee9c46785a13ca6_video-camera.png',
      active: false,
    },
    {
      type: 'GitHub',
      img_url: '/images/65048a233ee9c46785a13c8d_github-copy.png',
      active: false,
    },
    {
      type: 'Course',
      img_url:
        '/images/65048a233ee9c46785a13caa_book-copy_165048a233ee9c46785a13caa_book-copy.png',
      active: false,
    },
  ];

  const handleSelect = (type) => {
    dispatch(mentorActions.datasetUpdated({ ...dataset, type: type }));
    _handleUpdateModalStatus('dataset-url-resource-modal', true);
  };

  const getInActiveStatus = (item) => {
    if (!item.active) {
      return 'inactive';
    }
    return '';
  };

  return { datasetTypes, getInActiveStatus, handleSelect };
}
