export const findParentElementMatchingClass = (el, className) => {
  let parent = el.parentNode;
  while (parent) {
    if (parent.className.indexOf(className) < 0) {
      parent = parent.parentNode;
    } else {
      break;
    }
  }
  return parent;
};

export const getMentorLLMProviderName = (llmName) => {
  if (llmName?.toLowerCase()?.includes('gpt-4')) {
    return 'gpt-4';
  }

  if (llmName?.toLowerCase()?.includes('vertex')) {
    return 'google';
  }
};

export const createDataTable = (data) => {
  let table = document.createElement('table');
  table.id = 'export-message-table';
  table.style.display = 'none';

  let content = `
        <thead>
            <tr>
                <th>Message Type</th>
                <th>Content</th>
            </tr>
        </thead>
        <body>
    `;

  data?.forEach((item) => {
    content += `<tr>
                <td>${item?.message?.type}</td>
                <td>${item?.message?.data?.content}</td>
            </tr>`;
  });

  content += `</body>`;
  table.innerHTML = content;

  document.body.appendChild(table);
};

export function exportTableToExcel(tableId) {
  const table = document.getElementById(tableId);
  const html = table.outerHTML;
  const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'table.xls';

  a.click();
  URL.revokeObjectURL(url);
  table.remove();
}

export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function copyTextToClipBoard(textToCopy = '') {
  navigator.clipboard.writeText(textToCopy);
}

export const getFileExtension = (fileName) => {
  return fileName?.split('.').pop()?.toUpperCase();
};

export const handleStopBrowserStream = () => {
  if (!window.audioStreamReference) return;
  window.audioStreamReference.getAudioTracks().forEach(function (track) {
    track.stop();
  });
  window.audioStreamReference.getVideoTracks().forEach(function (track) {
    track.stop();
  });
  window.audioStreamReference = null;
};

export const mentorIsIframe = () => {
  return window.location !== window.parent.location;
};

export const convertFirstLetterUppercase = (text) => {
  return String(text).charAt(0).toUpperCase() + String(text).slice(1);
};

const getCurrentTenant = () => {
  const tenantStr = localStorage.getItem('current_tenant');
  if (tenantStr) {
    return JSON.parse(tenantStr);
  }
};

export const userIsAdmin = () => {
  const tenant = getCurrentTenant();
  if (tenant) {
    return tenant?.is_admin;
  }
};

export const userIsOnTrial = () => {
  const tenant = getCurrentTenant();
  if (tenant) {
    return tenant?.key === 'main' && tenant?.is_admin === false;
  }
  return false;
};

export const textTruncate = function (str, length, ending) {
  if (length == null) {
    length = 50;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const removeHtmlTags = (input) => {
  return String(input).replace(/<[^>]*>/g, '');
};

export const getHostFromUrl = (url) => {
  const a = document.createElement('a');
  a.href = url;
  return a.hostname;
};

const data = {};

let loaded = data?.loaded ?? 'default';

export const DOCUMENTS_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.PNG',
  '.JPG',
  '.JPEG',
  '.pdf',
  '.docx',
  '.doc',
  '.rtf',
  '.csv',
];
export const UPLOAD_MAX_SIZE = 1024 * 1024 * 10; //10Mo

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const getDatasetFormatExtensions = {
  TEXT: ['.rtf', '.txt'],
  PDF: ['.pdf'],
  DOCX: ['.docx'],
};

export const DEFAULT_INITIAL_MESSAGE = `I’m your helpful AI assistant. Not sure where to start? You can try:`;

export const DEFAULT_SUGGESTED_MESSAGE = `Can you help me write a research paper on the history of the Roman Empire?`;
