import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import useMentors from './useMentors';
import { useTriggers } from '../../navigation';
import { toast } from 'react-toastify';
import useApi from '../base/useApi';
import { useParams, useNavigate } from 'react-router-dom';
import useMentorDetails from './useMentorDetails';

export default function useSetCurrentMentor(start) {
  const [featured, setFeatured] = useState(true);
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const dispatch = useDispatch();
  const { loading, error } = useMentors(featured, start);
  const api = useApi();
  const { loadMentorDetails } = useMentorDetails();

  const { _handleUpdateModalStatus } = useTriggers();
  const pathParams = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const requestedMentor = pathParams?.mentor || urlParams.get('mentor');

  const _setCurrentMentor = (mentor) => {
    dispatch(mentorActions.currentMentorUpdated(mentor));
    if (!window.location.pathname.includes('share')) {
      navigate(`/platform/${auth.tenant.key}/${mentor.slug}`);
    }
  };

  const _getDefaultMentorFromList = (mentors) => {
    return mentors?.find((item) => item?.metadata?.default) || mentors[0];
  };

  const loadMentorPublicSettings = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${requestedMentor}/public-settings/`;
    const response = await api.get(url);
    if (response?.data) {
      const settings = response.data;
      dispatch(
        mentorActions.currentMentorUpdated({
          name: settings?.mentor,
          slug: settings?.mentor_slug,
          unique_id: settings?.mentor_unique_id,
          suggested_prompts: settings?.suggested_prompts,
          settings,
          proactive_prompt: settings?.proactive_prompt,
        })
      );
    } else {
      toast.error(`Could not load the requested mentor: ${requestedMentor}`);
    }
  };

  useEffect(() => {
    if (requestedMentor && !mentors?.mentor && auth?.tenant && auth?.user) {
      loadMentorPublicSettings();
    }
  }, [auth]);

  useEffect(() => {
    if (mentors?.featured?.results?.length === 0 && featured) {
      setFeatured(false);
    }
  }, [mentors?.featured]);

  useEffect(() => {
    if (mentors?.mentors?.results?.length === 0) {
      if (auth?.tenant?.is_admin) {
        dispatch(mentorActions.initialSetupUpdated(true));
        _handleUpdateModalStatus('mentor-creation-modal', true);
        toast.info("You don't have any mentors. Please create one");
      } else {
        window.location.assign('/no-mentors');
      }
    }
  }, [mentors?.mentors]);

  useEffect(() => {
    if (!mentors?.mentor) {
      if (mentors?.featured?.results?.length > 0 && !requestedMentor) {
        _setCurrentMentor(
          _getDefaultMentorFromList(mentors?.featured?.results)
        );
      } else if (mentors?.mentors?.results?.length > 0 && !requestedMentor) {
        _setCurrentMentor(mentors?.mentors?.results[0]);
      }
    }
  }, [mentors]);

  return { loading, error };
}
