import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { useContext, useEffect } from 'react';
import { AppDataContext, isJSON } from '@iblai/ibl-web-react-common';
import { navigationActions } from '../../lib/redux/navigation/slice';

export default function useIframeEvents() {
  const mentor = useSelector((state) => state.mentors.mentor);
  const dispatch = useDispatch();
  const { setAppData, appData } = useContext(AppDataContext);

  useEffect(() => {
    window.addEventListener('message', handleEnableCloserBtnEvent);
    return () => {
      window.removeEventListener('message', handleEnableCloserBtnEvent);
    };
  }, []);

  const handleEnableCloserBtnEvent = (event) => {
    if (!!event && !!isJSON(event?.data)) {
      const msg = JSON.parse(event.data);
      dispatch(
        navigationActions.iframeCloseButtonEnabled(!!msg?.enableCloseButton)
      );
    }
  };

  const handleThemePostMessage = (theme) => {
    const bodyEl = document.body;
    if (theme === 'dark') {
      bodyEl.classList.add('dark-mode');
    } else {
      bodyEl.classList.remove('dark-mode');
    }
  };

  const handleFocusPrompt = () => {
    document.getElementById('user-prompt')?.focus();
  };

  const handleCssPostMessage = (css) => {
    let style = document.createElement('style');
    style.innerText = css;
    document.head.appendChild(style);
  };

  const handleDefaultPromptChange = (data) => {
    dispatch(
      mentorActions.currentMentorUpdated({
        ...mentor,
        settings: {
          ...mentor?.settings,
          suggested_message: data?.defaultPrompt,
          initial_message: data?.welcomeMessage,
        },
      })
    );
  };

  const handleTokenMessage = (tokenData) => {
    Object.entries(tokenData).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });
    window.location.reload();
  };

  const notifyParentOnTokenExpiry = () => {
    window.parent.postMessage(JSON.stringify({ authExpired: true }), '*');
  };

  const notifyParentOnLoad = () => {
    window.parent.postMessage(
      JSON.stringify({ loaded: true, auth: { ...localStorage } }),
      '*'
    );
  };

  const notifyParentOnReady = () => {
    notifyParentOnLoad();
    if (!localStorage.getItem('axd_token')) {
      window.parent.postMessage(JSON.stringify({ ready: true }), '*');
    }
  };

  const handleIframeMessage = (event) => {
    const dataStr = event?.data;
    if (dataStr?.axd_token) {
      handleTokenMessage(dataStr);
    }

    if (typeof dataStr === 'string') {
      let data = null;
      try {
        data = JSON.parse(dataStr);
      } catch (e) {
        console.log(e);
      }

      if (data?.axd_token) {
        console.log('we got a data token', data);
        handleTokenMessage(data);
      }

      if (data?.css) {
        handleCssPostMessage(data?.css);
      }
      if (data?.theme) {
        handleThemePostMessage(data?.theme);
      }

      if (data?.focusPrompt?.toString() === '1') {
        handleFocusPrompt();
      }

      if (data?.internal_preview) {
        handleDefaultPromptChange(data);
      }
    }
  };

  return {
    handleIframeMessage,
    notifyParentOnReady,
    notifyParentOnTokenExpiry,
  };
}
