import Chathistorycontent from "../Chathistorycontent/Chathistorycontent";import React from "react";
import "./Chathistorybox.css";


const Chathistorybox = props => {
    
    return (
<div className="modal history-modal">
<div className="w-layout-vflex modal-container">
<div className="w-layout-hflex modal-header">
<h3 className="modal-header-title">History</h3>
<a className="default-btn w-button" href="#" prop-events-value-onclick="handleNewChatBtnClick">New Chat</a>
<div className="modal-close-wrapper" data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" prop-events-value-onclick="handleModalCloseClick"><img alt="" loading="lazy" src="/images/close_1close.png"/></div>
</div>
<div className="w-layout-vflex modal-body all-whiten-in-dark-mode">
<Chathistorycontent></Chathistorycontent>
<div className="w-layout-vflex history-element-container">
<div className="history-timeline">Previous 7 days<br/></div>
<div className="w-layout-hflex history-block greyish-bg-in-darkmode" data-w-id="daea0bfa-4b0f-b69a-371d-aa41ba6d0449">
<div className="history-left-side">
<div className="history-element-title">What's the weather in greenwich?</div>
</div>
<div className="history-right-side">
<div className="history-time">Yesterday</div>
<div className="w-layout-hflex history-action-btn-block">
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/edit.svg"/></div>
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/upload.svg"/></div>
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/trash-2.svg"/></div>
</div>
</div>
</div>
<div className="w-layout-hflex history-block greyish-bg-in-darkmode" data-w-id="f97b3dfa-fc2d-7180-8382-5bdcc6502c6a">
<div className="history-left-side">
<div className="history-element-title">What's the weather in greenwich?</div>
</div>
<div className="history-right-side">
<div className="history-time">5 days ago</div>
<div className="w-layout-hflex history-action-btn-block">
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/edit.svg"/></div>
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/upload.svg"/></div>
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/trash-2.svg"/></div>
</div>
</div>
</div>
</div>
<div className="w-layout-vflex history-element-container">
<div className="history-timeline">Previous 30 days<br/></div>
<div className="w-layout-hflex history-block greyish-bg-in-darkmode" data-w-id="1e02b184-398c-4868-df0c-51e1948fe24d">
<div className="history-left-side">
<div className="history-element-title">What's the weather in greenwich?</div>
</div>
<div className="history-right-side">
<div className="history-time">8 days ago</div>
<div className="w-layout-hflex history-action-btn-block">
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/edit.svg"/></div>
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/upload.svg"/></div>
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/trash-2.svg"/></div>
</div>
</div>
</div>
<div className="w-layout-hflex history-block greyish-bg-in-darkmode" data-w-id="1e02b184-398c-4868-df0c-51e1948fe25b">
<div className="history-left-side">
<div className="history-element-title">What's the weather in greenwich?</div>
</div>
<div className="history-right-side">
<div className="history-time">15 days ago</div>
<div className="w-layout-hflex history-action-btn-block">
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/edit.svg"/></div>
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/upload.svg"/></div>
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/trash-2.svg"/></div>
</div>
</div>
</div>
</div>
<div className="w-layout-vflex history-element-container last-history-element-container">
<div className="history-timeline">May<br/></div>
<div className="w-layout-hflex history-block greyish-bg-in-darkmode" data-w-id="a60fa53a-6718-0f11-d0c4-639c08b47a0b">
<div className="history-left-side">
<div className="history-element-title">What's the weather in greenwich?</div>
</div>
<div className="history-right-side">
<div className="history-time">35 days ago</div>
<div className="w-layout-hflex history-action-btn-block">
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/edit.svg"/></div>
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/upload.svg"/></div>
<div className="history-action-btn"><img alt="" className="history-action-img" loading="lazy" src="/images/trash-2.svg"/></div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
)}

export default Chathistorybox;