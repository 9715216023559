import React from 'react';
import './Defaultsuggestedprompttext.css';
import { useSelector } from 'react-redux';
import useSuggestedPrompt from '../../hooks/chat-room/useSuggestedPrompt';
import { DEFAULT_SUGGESTED_MESSAGE } from '../../utils/shared';

const Defaultsuggestedprompttext = (props) => {
  const mentors = useSelector((state) => state.mentors);
  let defaultPrompt = mentors?.mentor?.settings?.suggested_message;
  if (!defaultPrompt || defaultPrompt.trim() === '') {
    defaultPrompt = DEFAULT_SUGGESTED_MESSAGE;
  }

  const { handleSuggestedPromptSelection } = useSuggestedPrompt();

  return (
    <div
      className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode"
      map=""
      onClick={() => handleSuggestedPromptSelection(defaultPrompt)}
      map-value="label"
    >
      <div className="default-suggestion-prompt">{defaultPrompt}</div>
    </div>
  );
};

export default Defaultsuggestedprompttext;
