import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import { chatActions } from '../../../lib/redux/chat/slice';
import { useTriggers } from '../../navigation';
import useNewChat from '../../chat-room/useNewChat';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function useActiveMentor(mentor) {
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const chat = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const { _handleUpdateModalStatus } = useTriggers();
  const { handleNewChatBtnClick } = useNewChat();
  const navigate = useNavigate();

  const active =
    mentor.slug === mentors?.mentor?.slug ||
    mentor.name === mentors?.mentor?.name;

  const handleMentorBoxClick = () => {
    if (chat?.generating || chat?.streaming) {
      toast.warning('Your chat is streaming, please wait.');
      return;
    }
    if (mentors?.mentor?.name !== mentor?.name) {
      dispatch(mentorActions.currentMentorUpdated(mentor));
      dispatch(mentorActions.userSelectedMentorUpdated(true));
      dispatch(
        chatActions.chatUpdated({ ...(chat?.data ?? {}), messages: [] })
      );
      navigate(`/platform/${auth.tenant.key}/${mentor.slug}`);
      handleNewChatBtnClick();
    }

    _handleUpdateModalStatus('featured-mentors-modals', false);
  };

  return { active, handleMentorBoxClick };
}
