import React from 'react';
import useApi from '../../../hooks/api/base/useApi';
import { useSelector } from 'react-redux';
import { getHostFromUrl } from '../../../utils/shared';
import { BASE_API_URL } from '../../../utils/api';

export default function DatasetItem({ item }) {
  const [isTrained, setIsTrained] = React.useState(Boolean(item?.is_trained));
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const handleToggleTrainDataset = async (event) => {
    const url = `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.tenant?.key}/documents/${item?.id}/`;
    const data = {
      train: event.target.checked,
      pathway: item.pathway,
      url: item.url,
    };
    const response = await api.put(url, data);
    if (response?.data) {
      setIsTrained(response?.data?.is_trained);
    }
  };

  const includeUrlParams = (url) => {
    if(url.startsWith(BASE_API_URL)){
      url = `${url}&username=${auth?.user?.user_nicename}&tenant=${auth?.tenant?.key}`
    }
    return url;
  }

  return (
    <div className="w-layout-hflex table-body-row greyish-bg-in-darkmode">
      <div className="table-block table-block-very-big">
        {item.document_type.toLowerCase() === 'url' ? (

          <div className="table-text text-inline">
              {item.document_name || item.url}
            </div>
        ) : (
          <div className="table-text text-inline">
            {item.document_name || item.url}
          </div>
        )}
      </div>
      <div className="table-block table-block-medium">
        <div className="table-text">{item.document_type}</div>
      </div>
      <div className="table-block table-block-big bold-in-dark-mode">
        <div className="table-text">
          {item.tokens}
          <br />
        </div>
      </div>
      <div className="table-block table-block-large">
        <a
          title={item.url}
          href={`${includeUrlParams(item.url)}`}
          target="_blank"
          className="table-text-link underline-on-hover w-inline-block"
        >
          <span className="text-inline">{getHostFromUrl(item.url)}</span>
        </a>

      </div>
      <div className="table-block table-block-small">
        <div className="user-row-switcher-embed w-embed">
          <label className="user-switcher">
            <input
              onChange={handleToggleTrainDataset}
              type="checkbox"
              checked={isTrained}
            />
            <span className="user-switcher-slider round"></span>
          </label>
        </div>
      </div>
    </div>
  );
}
