import { useEffect, useState } from 'react';
import PageLoader from '../loader/page-loader';
import { useParams } from 'react-router-dom';
import { axdTokenIsExpired, redirectToAuth } from '../../utils/auth';
import { IndexView } from '../../views';
import useAnonymousMode from '../../hooks/iframe/useAnonymousMode';
import useIframeEvents from '../../hooks/iframe/useIframeEvents';

export default function Platform() {
  const pathParams = useParams();
  const redirectPath = `/platform/${pathParams.platform}/${pathParams.mentor}`;
  const redirectUrl = `${window.location.origin}`;
  const anonymous = useAnonymousMode();
  const { handleIframeMessage, notifyParentOnReady } = useIframeEvents();

  const [ready, setReady] = useState(false);

  const handlePlatformRequest = () => {
    const currentTenant = localStorage.getItem('tenant');
    if (
      !anonymous &&
      (pathParams.platform !== currentTenant || axdTokenIsExpired())
    ) {
      localStorage.setItem('redirect-path', redirectPath);
      redirectToAuth(true, redirectUrl, pathParams.platform);
    } else {
      setReady(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);
    notifyParentOnReady();
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  useEffect(() => {
    handlePlatformRequest();
  }, []);

  return <>{ready ? <IndexView /> : <PageLoader />}</>;
}
