import { useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { useParams } from 'react-router-dom';

export default function useMentorUtils() {
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();
  const pathParams = useParams();
  const urlParams = new URLSearchParams(window.location.search);

  const loadMentorDetails = async (name, tenant) => {
    const url = `/api/ai-mentor/orgs/${tenant}/users/${auth?.users?.user_nicename}/mentors/${name}/public-settings/`;
    const response = await api.get(url);
    if (response?.data) {
      return {
        name: response.data.mentor,
        settings: response.data,
        unique_id: response.data.mentor_unique_id,
        slug: response.data.mentor_unique_id,
        metadata: response?.data.metadata,
        suggested_prompts: response?.data.suggested_prompts,
        proactive_prompt: response?.data.proactive_prompt,
      };
    }
  };

  const _getMentorFromLocalStore = (name) => {
    return mentors?.featured?.results?.find(
      (item) => item.name === name || item.unique_id === name
    );
  };

  const getMentor = async (name) => {
    const localMentor = _getMentorFromLocalStore(name);
    if (localMentor) {
      return localMentor;
    }
    const tenant =
      auth?.tenant?.key ||
      pathParams.platform ||
      urlParams.get('tenant') ||
      'main';
    return await loadMentorDetails(name, tenant);
  };

  return { getMentor };
}
