import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApi from '../api/base/useApi';

export default function useSubscriptionStatus() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const loadSubscriptionsStatus = async () => {
    const url = `/api/service/orgs/main/users/${auth?.user?.user_nicename}/stripe/subscriptions/?sku=${process.env.REACT_APP_IBL_MENTOR_PRODUCT_SKU}`;
    const response = await api.get(url);
    if (response?.data) {
      if (response?.data?.status !== 'active') {
        setShow(true);
      }
    }
  };

  const handleGotoPaymentUpdate = async () => {
    const url = `/api/service/orgs/main/users/${auth?.user?.user_nicename}/stripe/customer-portal/`;
    const response = await api.get(url);
    if (response?.data) {
      window.location.assign(response?.data?.url);
    }
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_IBL_ENABLE_STRIPE === 'true' &&
      auth?.tenant?.is_admin &&
      auth?.tenant?.key !== 'main' &&
      !loading
    ) {
      // loadSubscriptionsStatus();
    }
  }, []);

  return { show, handleGotoPaymentUpdate };
}
