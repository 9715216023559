import Mobilesettingsmenudropdownbox from '../Mobilesettingsmenudropdownbox/Mobilesettingsmenudropdownbox';
import React from 'react';
import './Helpmenudropdownbox.css';
import { useTriggers } from '../../hooks/navigation';

const Helpmenudropdownbox = (props) => {
  const { handleHelpMenuClick, handleFaqMenuClick } = useTriggers();
  return (
    <nav className="header-help-dropdown-container help-dropdown w-dropdown-list">
      <div className="w-layout-vflex user-menu-dropdown-block black-bg-dark-mode all-whiten-in-dark-mode whiten-border-in-dark-mode help-user-menu-dropdown-block">
        {/*<a
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
          href="#"
          prop-events-value-onclick="handleUpdateBtnClick"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="/images/note-svgrepo-com.svg"
          />
          <div className="user-menu-label">Updates</div>
        </a>*/}
        <a
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
          data-w-id="173f0889-c967-79aa-41d4-5e029a3d5141"
          onClick={handleHelpMenuClick}
          href="#"
          prop-events-value-onclick="handleHelpBtnClick"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="/images/info-message-svgrepo-com.svg"
          />
          <div className="user-menu-label">Help</div>
        </a>
        <a
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
          href="#"
          prop-events-value-onclick="handleFAQBtnClick"
          onClick={handleFaqMenuClick}
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="/images/question-answer-svgrepo-com.svg"
          />
          <div className="user-menu-label">FAQ</div>
        </a>
        <a
          href="mailto:support@iblai.zendesk.com"
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
        >
          <img
            src="/images/privacy-tips-svgrepo-com.svg"
            loading="lazy"
            alt="Support Button Image"
            className="user-menu-icon"
          />
          <div className="user-menu-label">Support</div>
        </a>
      </div>
    </nav>
  );
};

export default Helpmenudropdownbox;
